import api from '@/services/api';
import { arrayToObject, saveFileFromBackend } from '@/assets/js/utility';
import { make } from 'vuex-pathify';
import { availableFormPrints } from '@/assets/js/variables';

const state = {
  datasources: {},
  formPrints: [],
  layers: {},
  layersGroups: {},
  notes: [],
  projects: {},
  searchEngines: [],
  searchObjectsResultGeometry: null,
  settings: {},
};

const mutations = {
  ...make.mutations(state),
};

const actions = {
  async getSettings({ commit }) {
    const r = await api.get('settings');
    commit(
      'SET_SETTINGS',
      Object.keys(r.data.data || {}).reduce((total, current) => {
        total[current] = r.data.data?.[current]?.value;
        return total;
      }, {})
    );
  },
  async getDatasources({ commit }) {
    const r = await api.get('datasources/metadata');
    commit('SET_DATASOURCES', Object.freeze(arrayToObject(r.data.data, 'name')));
  },
  async getLayers({ commit }) {
    const r = await api.get('layers');
    commit('SET_LAYERS', Object.freeze(arrayToObject(r.data.data)));
  },
  async getLayersGroups({ commit }) {
    const r = await api.get('layers/layer_in_groups');
    const layers = (r.data?.data || []).sort((a, b) => a.order - b.order);
    layers.forEach(group => group.layers.sort((a, b) => a.order_in_group - b.order_in_group));
    commit('SET_LAYERS_GROUPS', Object.freeze(layers));
  },
  async getLuminareSymbolization(store, layerId) {
    return (await api.get(`layers/symbolization/${layerId}`))?.data.data;
  },
  async getProjects({ commit }) {
    const r = await api.get('predefined_maps');
    commit('SET_PROJECTS', Object.freeze(arrayToObject(r.data.data)));
  },
  async getNotes({ commit }, { dataSourceName, featureId } = {}) {
    const r = await api.get(`notes/${dataSourceName}/${featureId}`);
    commit('SET_NOTES', r.data.data);
  },
  async addNote({ dispatch }, { dataSourceName, featureId, payload } = {}) {
    await api.post(`notes/${dataSourceName}/${featureId}`, payload);
    await dispatch('getNotes', { dataSourceName, featureId });
  },
  async deleteNote({ dispatch }, { dataSourceName, featureId, noteId } = {}) {
    await api.delete(`notes/${noteId}`);
    await dispatch('getNotes', { dataSourceName, featureId });
  },
  async getSearchEngines({ commit }) {
    const r = await api.get('search_engines');
    commit('SET_SEARCH_ENGINES', r.data.data);
  },
  async engineSearch(store, { engineId, query }) {
    const r = await api.get(`search/${engineId}/${query}`);
    return { ...r.data.data, features: r.data.data.features.slice(0, 50) };
  },
  async getFormPrints({ commit }) {
    const r = await api.get('form_prints');
    commit(
      'SET_FORM_PRINTS',
      r.data.data.filter(f => Object.keys(availableFormPrints).includes(f.form))
    );
  },
  async formPrint(store, { form, formPrintId, featureId, fileName } = {}) {
    const r = await api.post(
      `form_prints/${form}/${featureId}`,
      { form_print: formPrintId },
      { responseType: 'arraybuffer' }
    );
    saveFileFromBackend(r.data, r.headers, fileName);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
